import { SocialMediaProps } from './SocialMedia.types'

export function SocialMedia({ socialMedia }: SocialMediaProps) {
  return (
    <div className="w-full">
      <h5 className="mb-24 text-center text-sm font-bold text-white desktop:mb-12 desktop:text-left">
        Mídias Sociais
      </h5>
      <ul className="mx-auto flex w-full max-w-[462px] justify-evenly desktop:mx-0 desktop:max-w-[128px] desktop:flex-wrap desktop:justify-start desktop:gap-8">
        {socialMedia?.map((media) => (
          <li key={media.id}>
            <a
              className="[&>svg]:size-32 desktop:[&>svg]:size-24"
              href={media.link}
              dangerouslySetInnerHTML={{ __html: media.component }}
              target="_blank"
              rel="noreferrer"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
