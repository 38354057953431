import { SectionProps } from './LinksSection.types'

export function LinksSection({
  title,
  linksList,
  limitedHeight
}: SectionProps) {
  return (
    <>
      <h5 className="mb-12 text-sm font-bold text-white">{title}</h5>
      <ul
        className={`mb-[40px] flex flex-col flex-wrap gap-8
          ${limitedHeight ? 'max-h-[30rem] desktopLarge:max-h-[26rem]' : ''}
        `}
      >
        {linksList?.map((item) => (
          <li
            key={item.title}
            className="leading-[1.125rem] desktop:max-w-[120px] desktopLarge:max-w-none"
          >
            <a
              href={item.link}
              className="text-xs text-white hover:opacity-80"
              target={item.target}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}
