import { useEffect } from 'react'

import { IconArrowOrangeLeft, IconCollapseHeader } from '@k_frontend/icons'
import theme from 'theme'

import { LinksModalProps } from './LinksModal.types'

export function LinksModal({
  dataModal,
  title,
  setIsOpen,
  isOpen
}: LinksModalProps) {
  useEffect(() => {
    const html = document?.querySelector('html')
    const body = document?.querySelector('body')
    const header = document?.querySelector('#header') as HTMLElement
    if (html?.style) {
      html.style.overflow = isOpen ? 'hidden' : 'unset'
    }
    if (body?.style) {
      html.style.overflow = isOpen ? 'hidden' : 'unset'
    }
    if (header?.style) {
      header.style.position = isOpen ? 'fixed' : 'relative'
    }

    return () => {
      if (html?.style) {
        html.style.overflow = 'unset'
      }
      if (body?.style) {
        html.style.overflow = 'unset'
      }
      if (header?.style) {
        header.style.position = 'relative'
      }
    }
  }, [isOpen])

  return (
    <div className="fixed left-0 top-0 z-[9999] flex h-dvh w-full flex-col gap-16 bg-black-400 p-16">
      <div className="flex items-center gap-16">
        <button onClick={() => setIsOpen(false)} aria-label="Fechar modal">
          <IconArrowOrangeLeft className="size-12" />
        </button>
        <h3 className="text-base font-bold uppercase leading-7 text-black-800">
          {title}
        </h3>
      </div>
      <div className="relative flex animate-[slideToLeft_0.4s_ease-in-out] flex-col gap-[1px] overflow-y-auto">
        {dataModal.map((item, index) => (
          <a
            key={index}
            href={item.link}
            target={item.target}
            className="flex w-full items-center justify-between rounded-4 bg-white px-16 py-12 text-sm/6 text-black-700"
          >
            {item.title}
            <IconCollapseHeader
              className="size-12 rotate-90"
              color={theme.colors.black(700)}
            />
          </a>
        ))}
      </div>
    </div>
  )
}
