import { KabumInfo } from 'components/Footer/KabumInfo'
import { Stamps } from 'components/Footer/Stamps'

import { FooterProps } from './Footer.types'

export default function Footer({
  footerContent,
  isPrime,
  titleId,
  children,
  hasDrawer,
  cleanedLinks
}: FooterProps) {
  return (
    <footer id="footer" aria-labelledby={titleId} className="bg-white-500">
      <h2 className="sr-only" id={titleId}>
        KaBuM! Rodapé
      </h2>
      <div id="linksFooter">{children}</div>
      <Stamps />
      {cleanedLinks}
      <KabumInfo
        text={footerContent?.brandRights}
        isPrime={isPrime}
        hasDrawer={hasDrawer}
      />
    </footer>
  )
}
