import { FooterMobile } from 'components/Footer/Mobile'

import { useFooterMobileContainer } from './FooterMobile.hook'
import { FooterMobileContainerProps } from './FooterMobile.types'

export function FooterMobileContainer({
  footerContent,
  isPrime
}: FooterMobileContainerProps) {
  const footerMobileProps = useFooterMobileContainer()

  return (
    <FooterMobile
      isPrime={isPrime}
      footerContent={footerContent}
      {...footerMobileProps}
    />
  )
}
