import Image from 'next/image'

import { STATIC } from 'infra/environmentVariants'

import { IconMagalu } from '@k_frontend/icons'
import { kabumInfoWrapper } from './KabumInfo.styles'
import { KabumInfoProps } from './KabumInfo.types'

export function KabumInfo({ text, isPrime, hasDrawer }: KabumInfoProps) {
  return (
    <div
      className={kabumInfoWrapper({ isPrime, hasDrawer })}
      id='informacoesKabumFooter'
    >
      <div className='container flex flex-col items-center gap-24 py-16'>
        <Image
          width='158'
          height='54'
          src={`${STATIC}/conteudo/icons/${
            isPrime ? 'logoPrime.svg' : 'logo.svg'
          }`}
          alt='Logo Kabum'
          className='mt-8 desktop:hidden'
        />
        <div className='flex items-center justify-center w-full'>
          <span className='flex w-full items-center justify-center justify-items-center text-white-500 text-xs font-semibold [&_svg]:ml-6 [&_svg]:mt-4 [&_svg]:w-80 [&_svg]:h-24'>
            Uma empresa do grupo <IconMagalu />
          </span>
        </div>
        <p
          className='px-16 text-center text-xxs text-white *:text-xxs desktop:px-32 [&>a]:underline'
          dangerouslySetInnerHTML={{ __html: text || '' }}
        ></p>
      </div>
    </div>
  )
}
