import { useState } from 'react'

import { useDepartmentsMenu } from '@k_frontend/core'

import { LinkDefault } from './FooterMobile.types'

export function useFooterMobileContainer() {
  const { openDepartments } = useDepartmentsMenu()
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false)
  const [dataModal, setDataModal] = useState<{
    data: LinkDefault[]
    title: string
  }>()

  function handleDataModal(data: LinkDefault[], title: string) {
    setIsOpenMobileModal(true)
    setDataModal({ data, title })
  }

  return {
    dataModal,
    isOpenMobileModal,
    setIsOpenMobileModal,
    handleDataModal,
    openDepartments
  }
}
