import Link from 'next/link'

import * as S from './CleanedLinks.styles'

export function CleanedLinks({ isPrime }) {
  return (
    <div className={S.footerWrapper({ isPrime })}>
      <div className="container flex w-max max-w-lg flex-col px-0 tablet:flex-row tablet:items-center tablet:gap-24">
        <Link
          href="/politicas"
          className="text-xxs text-white-500 no-underline tablet:leading-7"
        >
          Políticas do Site e Marketplace
        </Link>
        <Link
          href="/privacidade"
          className="text-xxs text-white-500 no-underline tablet:leading-7"
        >
          Políticas de Privacidade
        </Link>
        <Link
          href="/portaldeprivacidade"
          className="text-xxs text-white-500 no-underline tablet:leading-7"
        >
          Exerça seus Direitos de Privacidade
        </Link>
      </div>
    </div>
  )
}
