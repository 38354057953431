export function FooterLoading() {
  function loadingLinksGenerate(linksQuantity: number) {
    return Array(linksQuantity)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="h-[18px] w-full animate-pulse rounded-2 bg-primary-300 desktop:max-w-[138px]"
        />
      ))
  }

  function desktopLinks() {
    return (
      <>
        <div className="container hidden grid-cols-12 gap-32 desktop:grid">
          <div className="col-span-3">
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex max-h-[23rem] flex-col flex-wrap gap-8">
              {loadingLinksGenerate(25)}
            </div>
          </div>

          <div className="col-span-2">
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(16)}
            </div>
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(1)}
            </div>
          </div>

          <div className="col-span-2">
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(16)}
            </div>
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(1)}
            </div>
          </div>

          <div className="col-span-2">
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(1)}
            </div>
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(2)}
            </div>
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(3)}
            </div>
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(3)}
            </div>
          </div>

          <div className="col-span-3">
            <div className="mb-12 h-[18px] w-2/3 animate-pulse rounded-2 bg-primary-300" />
            <div className="mb-[40px] flex flex-col flex-wrap gap-8">
              {loadingLinksGenerate(10)}
            </div>
          </div>
        </div>
      </>
    )
  }

  function mobileLinks() {
    return (
      <>
        <div className="container flex flex-col py-24 desktop:hidden">
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>
          <div className="border-0 border-b border-solid border-primary-500 py-16">
            <div className="h-[19px] w-4/5 animate-pulse rounded-2 bg-primary-300 " />
          </div>

          <div className="mt-16 flex flex-col items-center justify-center">
            <div className="mb-24 h-[18px] w-[100px] animate-pulse rounded-2 bg-primary-300" />
            <div className="mx-auto flex w-full max-w-[462px] justify-evenly">
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
              <div className="size-32 animate-pulse rounded-full bg-primary-300" />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex w-full bg-primary-600 desktop:pt-32">
        {desktopLinks()}
        {mobileLinks()}
      </div>
      <div className="container flex flex-wrap items-center justify-center gap-x-24 gap-y-16 py-16 desktop:gap-x-[6rem]">
        <div className="h-32 w-[115px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-[30px] w-[148px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-[46px] w-[130px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-[36px] w-[150px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-[56px] w-[57px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-48 w-[138px] animate-pulse rounded-2 bg-black-500" />
        <div className="h-[55px] w-[105px] animate-pulse rounded-2 bg-black-500" />
      </div>
      <div className="w-full bg-primary-700">
        <div className="container py-16">
          <div className="h-[120px] w-full animate-pulse rounded-2 bg-primary-300 px-32 desktop:h-48" />
        </div>
      </div>
    </>
  )
}
