import { tv } from 'tailwind-variants'

export const footerWrapper = tv({
  base: 'w-full bg-primary-600',
  variants: {
    isPrime: {
      true: 'bg-black-800'
    }
  }
})

export const footerLink = tv({
  base: 'bottom-0 flex w-full items-center justify-between border-0 border-b border-solid border-primary-500 py-16 text-xs font-semibold leading-5',
  variants: {
    isPrime: {
      true: 'border-black-700'
    }
  }
})
