import { tv } from 'tailwind-variants'

export const kabumInfoWrapper = tv({
  base: 'w-full bg-primary-700',
  variants: {
    isPrime: {
      true: 'bg-black-900'
    },
    hasDrawer: {
      true: 'pb-64 desktop:mb-0'
    }
  }
})
