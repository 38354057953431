import Image from 'next/image'
import Script from 'next/script'

import { ENVIRONMENT, STATIC } from 'infra/environmentVariants'

export function Stamps() {
  return (
    <div className="container flex flex-wrap items-center justify-center gap-x-24 gap-y-16 bg-white-500 py-16 desktop:gap-x-[6rem]">
      {ENVIRONMENT === 'PRODUCTION' && (
        <div id="armored_website">
          <param id="aw_preload" value="true" />
          <param id="aw_use_cdn" value="true" />
          <Script
            id="siteblindado"
            type="text/javascript"
            src="//cdn.siteblindado.com/aw.js"
          ></Script>
        </div>
      )}
      <Image
        src={`${STATIC}/conteudo/temas/001/imagens/rodape/selo_empresabuscape_horizontal.png`}
        width={148}
        height={30}
        alt="Empresa reconhecida buscapé"
        loading="lazy"
      />
      <a
        href="https://transparencyreport.google.com/safe-browsing/search?url=kabum.com.br&amp;hl=pt-PT"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src={`${STATIC}/conteudo/temas/001/imagens/rodape/selo-google-site-seguro.png`}
          width={130}
          height={46}
          alt="GOOGLE SITE SEGURO"
          loading="lazy"
        />
      </a>
      <a
        href="#"
        onClick={() => {
          window.open(
            'https://www.ebit.com.br/kabum',
            'ebit_medalha',
            'top=30,left=30,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=1100,height=600'
          )
          return false
        }}
      >
        <Image
          src={`${STATIC}/conteudo/temas/001/imagens/rodape/selo_ebit_kabum_horizontal.png`}
          width={150}
          height={36}
          alt="Ebit diamante excelente"
          loading="lazy"
        />
      </a>
      <a
        id="seloEconfy"
        href={`https://www.compreconfie.com.br/103388`}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          className="compreConfieImg h-[56px]"
          src="https://selo.compreconfie.com.br/reputation/103388.png"
          width={57}
          height={56}
          alt="Compre e confie"
          loading="lazy"
          style={{ objectFit: 'contain' }}
        />
      </a>
      <Script
        type="text/javascript"
        id="getData"
        src={`https://selo.compreconfie.com.br/scripts/getData.js?sellerId=103388`}
        strategy="afterInteractive"
      />
      <div id="reputation-ra">
        <Script
          type="text/javascript"
          id="ra-embed-reputation"
          src="https://s3.amazonaws.com/raichu-beta/selos/bundle.js"
          data-id="NzI1NDprYWJ1bQ=="
          data-target="reputation-ra"
          data-model="2"
          strategy="afterInteractive"
        />
      </div>
      <Image
        src={`${STATIC}/conteudo/temas/001/imagens/rodape/selo-ra.png`}
        height={60}
        width={105}
        alt="Empresa campeã prêmio reclame aqui 2022"
        loading="lazy"
      />
    </div>
  )
}
