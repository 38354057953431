import { tv } from 'tailwind-variants'

export const footerWrapper = tv({
  base: 'flex w-full bg-primary-500 p-16',
  variants: {
    isPrime: {
      true: 'bg-black-900'
    }
  }
})
