import { useId } from 'react'
import { useQuery } from 'react-query'

import { useUser } from '@k_frontend/core'
import { FooterService } from 'services/footer'
import { shallow } from 'zustand/shallow'

import { UseFooterContainerProps } from './Footer.types'

export function useFooterContainer({
  footerService = new FooterService(),
  pathName
}: UseFooterContainerProps) {
  const isPrime = useUser((state) => state.isPrime, shallow)
  const titleId = useId()

  const drawerPages = ['/precarrinho/[...slug]', '/produto/[...slug]']
  const hasDrawer = drawerPages.includes(pathName)

  const cleanFooterRoutes = [
    '/precarrinho/[...slug]',
    '/limite-excedido',
    '/oferta-esgotada'
  ]

  const isClean = cleanFooterRoutes.includes(pathName)

  const { data, isLoading } = useQuery({
    queryKey: ['getFooterContent'],
    queryFn: () => footerService.getFooterLinks()
  })

  return {
    data,
    isLoading,
    isPrime,
    titleId,
    hasDrawer,
    isClean
  }
}
