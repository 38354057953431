import { UnexpectedError } from '@k_frontend/core'
import { HttpClient } from 'adapters/httpClient'
import { SessionStorageClientAdapter } from 'adapters/sessionStorageClient'

import { FooterContent } from './footer.types'

export class FooterService {
  constructor(
    private readonly httpClient = new HttpClient(),
    private readonly sessionStorageClient = new SessionStorageClientAdapter()
  ) {}

  async getFooterLinks(): Promise<FooterContent> {
    let data: FooterContent | undefined
    data = this.sessionStorageClient.get('footerContent')

    if (!data) {
      const { body, error } = await this.httpClient.request<FooterContent>({
        url: `${process.env.NEXT_PUBLIC_STATIC_URL}/conteudo/footer/footer.json`,
        method: 'get'
      })

      if (!body || error) {
        throw new UnexpectedError()
      }

      data = body
      this.sessionStorageClient.set('footerContent', data)
    }

    return data
  }
}
