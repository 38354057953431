import { IconArrowOrangeRight as IconArrowRight } from '@k_frontend/icons'
import { SocialMedia } from 'components/Footer/SocialMedia'
import theme from 'theme'

import * as S from './FooterMobile.styles'
import { FooterMobileProps } from './FooterMobile.types'
import { LinksModal } from './LinksModal'

export function FooterMobile({
  footerContent,
  isPrime,
  handleDataModal,
  isOpenMobileModal,
  setIsOpenMobileModal,
  dataModal,
  openDepartments
}: FooterMobileProps) {
  return (
    <div className={S.footerWrapper({ isPrime })}>
      <div className="container flex flex-col py-24 text-white">
        <ul>
          <li>
            <button
              onClick={() => openDepartments()}
              className={S.footerLink({ isPrime })}
            >
              Departamentos
              <IconArrowRight color={theme.colors.white(500)} aria-hidden />
            </button>
          </li>

          {footerContent?.linksMobile.map((item) => (
            <li key={item.title}>
              {item.hasChildLinks ? (
                <button
                  className={S.footerLink({ isPrime })}
                  onClick={() =>
                    handleDataModal(
                      footerContent?.[item.childLinks],
                      item.title
                    )
                  }
                >
                  {item.title}
                  <IconArrowRight color={theme.colors.white(500)} aria-hidden />
                </button>
              ) : (
                <a
                  href={item.link}
                  className={S.footerLink({ isPrime })}
                  target={item.target}
                >
                  {item.title}
                  <IconArrowRight color={theme.colors.white(500)} aria-hidden />
                </a>
              )}
            </li>
          ))}
        </ul>

        <div className="mt-16">
          <SocialMedia socialMedia={footerContent?.socialMedia} />
        </div>
      </div>

      {isOpenMobileModal && (
        <LinksModal
          dataModal={dataModal.data}
          title={dataModal.title}
          setIsOpen={setIsOpenMobileModal}
          isOpen={isOpenMobileModal}
        />
      )}
    </div>
  )
}
