import { IconBalloonChat } from '@k_frontend/icons'
import { LinksSection } from 'components/Footer/LinksSection/LinksSection'
import { SocialMedia } from 'components/Footer/SocialMedia'
import { FaqContainer } from 'containers/Faq'
import { tailwindTheme } from 'theme/tailwindTheme'

import * as S from './FooterDesktop.styles'
import { FooterDesktopProps } from './FooterDesktop.types'

export function FooterDesktop({ footerContent, isPrime }: FooterDesktopProps) {
  return (
    <div className={S.footerWrapper({ isPrime })}>
      <div className='container grid grid-cols-12 gap-32'>
        <div className='col-span-3'>
          <LinksSection
            title='Departamentos'
            linksList={footerContent?.departmentslinks}
            limitedHeight
          />
        </div>
        <div className='col-span-2'>
          <LinksSection
            title='Novidades e Promoções'
            linksList={footerContent?.newsAndPromotionsinks}
          />
        </div>
        <div className='col-span-2'>
          <LinksSection
            title='Institucional'
            linksList={footerContent?.institucionallinks}
          />

          <LinksSection
            title='Compra segura'
            linksList={footerContent?.warranty}
          />
        </div>
        <div className='col-span-2'>
          <LinksSection
            title='Acessibilidade'
            linksList={footerContent?.accessibilityLinks}
          />

          <LinksSection
            title='Minha Conta'
            linksList={footerContent?.myAccountlinks}
          />

          <SocialMedia socialMedia={footerContent?.socialMedia} />

          <div className='mt-32 flex flex-col gap-8'>
            <p
              className='mb-8 text-sm font-bold text-white'
              id='downloadAppsColumn'
            >
              Baixe os aplicativos
            </p>
            {footerContent?.applinks?.map((app, index) => (
              <a
                key={index}
                dangerouslySetInnerHTML={{
                  __html: app.component
                }}
                href={app.link}
                target={app.target}
                aria-label={app.label || ''}
                className='[&>svg]:h-40 [&>svg]:w-[132px] [&>svg]:hover:opacity-80'
              ></a>
            ))}
          </div>
        </div>

        <div className='col-span-3'>
          <h5 className='mb-16 text-sm font-bold text-white'>Atendimento</h5>
          <div className='flex flex-col gap-12 text-xs'>
            <div
              className='flex flex-col gap-2 text-white [&>p]:text-xs'
              dangerouslySetInnerHTML={{
                __html: footerContent?.attendanceTime || ''
              }}
            />

            <div
              className='flex flex-col gap-2 text-white [&>p]:text-xs'
              dangerouslySetInnerHTML={{ __html: footerContent?.address || '' }}
            />

            <div className='flex flex-col gap-2 text-white'>
              <p className='text-sm font-bold' id='centralSacTitle'>
                Central SAC:
              </p>
              <a
                href='/faq'
                className='text-xs text-white-500 font-normal mt-8 hover:text-black-500'
              >
                Clique aqui
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
