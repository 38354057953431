import { useRouter } from 'next/router'

import { ResizeHOC } from '@k_frontend/core'
import Footer from 'components/Footer'
import { CleanedLinks } from 'components/Footer/CleanedLinks'
import { FooterDesktop } from 'components/Footer/Desktop'
import { FooterLoading } from 'components/Footer/Footer.loading'
import { FooterResizeProps } from 'components/Footer/Footer.types'
import { FooterMobileContainer } from 'containers/FooterMobile/FooterMobile'

import { useFooterContainer } from './Footer.hook'

export function FooterContainer() {
  const router = useRouter()
  const { data, isLoading, isPrime, titleId, hasDrawer, isClean } =
    useFooterContainer({
      pathName: router.pathname
    })

  if (isLoading) return <FooterLoading />

  if (!data) return null

  return (
    <Footer
      footerContent={data}
      isPrime={isPrime}
      titleId={titleId}
      hasDrawer={hasDrawer}
      cleanedLinks={isClean && <CleanedLinks isPrime={isPrime} />}
    >
      {!isClean && (
        <ResizeHOC<FooterResizeProps>
          DesktopComponent={FooterDesktop}
          MobileComponent={FooterMobileContainer}
          footerContent={data}
          isPrime={isPrime}
        />
      )}
    </Footer>
  )
}
