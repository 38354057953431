export class SessionStorageClientAdapter {
  get<T>(key: string): T | undefined {
    const dataStorage = sessionStorage.getItem(key)
    if (dataStorage) return JSON.parse(dataStorage) as T
    return undefined
  }

  set(key: string, value: unknown): void {
    const stringValue = JSON.stringify(value)
    sessionStorage.setItem(key, stringValue)
  }

  remove(key: string): void {
    sessionStorage.removeItem(key)
  }
}
